import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import {
  HeaderFontCss,
  ParagraphFontCss,
  ButtonFontCss,
} from "../Utils/CssStyleTemplateUtil";
import { s } from "../Utils/Lang";
import { StaticImage } from "../Components/StaticImage";
import { isDesktop, isPhone } from "../Utils/WindowUtil";
import * as Widget from "../Components/Widget";
import { ArrowDown, ArrowUp } from "../Components/Icon";

import DATA from "../../content/labs-data";

class ProjectListPage extends Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate() {
    window && window.scrollTo(0, 0);
  }

  render() {
    let { navActions } = this.props;

    return (
      <Wrapper>
        <iframe
          src="https://consult.revtel.tech/"
          style={{ width: "100%", height: "100%", minHeight: "100vh" }}
        ></iframe>
      </Wrapper>
    );

    return (
      <Wrapper>
        <div
          className="introduction-section"
          isDesktop={isDesktop}
          style={{
            width: "100%",
          }}
        >
          <h1 className="title" style={{ fontSize: 26, marginBottom: 16 }}>
            RevLabs
          </h1>
          <h2 style={{ fontSize: 16, fontWeight: 400 }}>
            {this.props.lang === "tw"
              ? "各種研發產出及自發項目"
              : "Various R&D Outputs and Self-initiated Projects"}
          </h2>{" "}
        </div>
        <div className="project-list-section">
          <div className="project-items-container">
            {DATA.map((item, idx) => {
              return (
                <a
                  key={idx}
                  className="project-item"
                  href={
                    this.props.lang === "en" || !item[3]
                      ? item[2]
                      : "/labs/" + item[3]["id"]
                  }
                  target="_blank"
                  aria-label={item[0]}
                  alt={item[0]}
                  style={{
                    cursor: "pointer",
                    padding: 2,
                    backgroundColor: "#EEEEEE",
                  }}
                >
                  <ProjectItem item={item} key={idx} lang={this.props.lang} />
                </a>
              );
            })}
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 360px;
    flex-grow: 0;
    flex-shrink: 0;

    & h2 {
      letter-spacing: 2px;
      font-size: 26px;
      ${HeaderFontCss}
      margin-bottom: 20px;
    }

    & p {
      font-size: 14px;
      ${ParagraphFontCss}
      white-space: pre-wrap;
      text-align: justify;
      line-height: 20px;
    }

    & button {
      margin-top: 40px;
      align-self: flex-start;
    }
  }

  .project-list-section {
    flex-grow: 1;
    background-color: #f1f1f1;
    padding-bottom: 10px;

    & .filter-bar-container {
      min-height: 50px;
      display: flex;
      align-items: stretch;
    }
    & .project-items-container {
      display: flex;
      flex-wrap: wrap;
      & .project-item {
        flex-basis: calc(100% / 2);
      }
    }
  }

  @media screen and (max-width: 900px) {
    .introduction-section {
      position: fixed;
      z-index: 5;

      height: ${props => "120px"};

      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 30px 40px;
      flex-basis: initial;

      flex-grow: 1;
      & h2 {
        letter-spacing: 2px;
        text-align: left;
      }

      & button {
        align-self: center;
      }
    }

    .project-list-section {
      margin-top: ${props => (props.isDesktop ? "0px" : "120px")};
    }
  }

  @media screen and (max-width: 500px) {
    .introduction-section {
      padding: 30px 25px 50px;
      & button {
        margin-top: 30px;
      }
    }
    .project-list-section {
      & .filter-bar-container {
      }
      & .project-items-container {
        & .project-item {
          flex-basis: 100%;
        }
      }
    }
  }
`;

class ProjectItem extends Component {
  render() {
    let { item, lang } = this.props;
    return (
      <ProjectItemWrapper>
        <img
          src={item[1]}
          alt={item[0]}
          style={{
            backgroundSize: "cover",
            height: 250,
            width: "100%",
            //maxWidth: "100%",
          }}
        />

        <div className="info-container">
          <h3 className="title" style={{}}>
            {item[0][lang]}
          </h3>
          {lang === "tw" && (
            <p
              style={{
                textDecoration: "underline",
                color: "lightgrey",
                fontSize: 16,
              }}
            >
              More Info
            </p>
          )}
        </div>
        {lang === "tw" && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: 35,
              height: 35,
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 3,
              backgroundColor: "#EEEEEE",
            }}
          >
            <img src="/images/arrow-right.png" width="25" height="25" />
          </div>
        )}
      </ProjectItemWrapper>
    );
  }
}

const ProjectItemWrapper = styled.div`
  position: relative;
  height: 100%;

  border-radius: 10px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .info-container {
    //background-color: red;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 50%;
    width: 100%;
    background-image: linear-gradient(
      0deg,
      #4e4e4e 0%,
      rgba(170, 170, 170, 0.01) 89%
    );
    padding: 0px 20px 15px 20px;
    display: flex;
    align-items: flex-end;
    //flex-direction: column;
    font-size: 20px;
    color: white;
    letter-spacing: 1px;
    ${HeaderFontCss}
    justify-content: space-between;

    & .title {
    }
    & .date {
    }
  }
`;

ProjectItem = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(ProjectItem);

export default withPage(
  connect((state, ownProps) => ({ ownProps }), ActionCreator)(ProjectListPage)
);
